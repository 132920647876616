<template>
  <div>
    <p>在excel模板中填写好客户信息，上传模板进行批量导入</p>
    <div style="margin-top: 20px;">
      <a-button @click="download('/api/agency/institutionsCustomer/downloadCustomerTemplate')" type="link">下载模板</a-button>
      <a-upload v-model:file-list="fileList" :before-upload="beforeUpload" name="file"
        action="/api/agency/institutionsCustomer/uploadCustomer" :headers="headers" @change="handleChange">
        <a-button type="link">上传模板</a-button>
      </a-upload>
    </div>
    <div style="margin-top: 60px; color: #999999">
      <p>Excel模板说明：</p>
      <p>1.一行一条客资。</p>
      <p>2.特殊输入说明：</p>
      <p>①【微信号】和【手机号】二选一必填。</p>
      <p>②【客户来源】【咨询项目】【意向等级】【意向国家】【性别】【最高学历】【语言成绩类型】输入的内容必须和系统的选项一致，请核对系统后填写。</p>
      <p>③【预算】只能输入数字，单位是万。</p>
      <p>④【毕业时间】输入格式年-月-日 例2023-12-26。</p>
    </div>
    <a-modal v-model:open="openModal" :maskClosable="false" width="300px" title="上传成功">
      <p style="margin-top: 30px; text-align: center;">客户导入成功：{{ result.successNum }} 条</p>
      <p style="margin-top: 10px; text-align: center;">客户导入失败：{{ result.failNum }} 条</p>
      <div style="margin-top: 20px; margin-bottom: 40px; text-align: center;">
        <a-button type="link"
          @click="download('/api/agency/institutionsCustomer/downloadCustomerResult')">查看导入结果</a-button>
      </div>
      <template #footer>
        <div style="text-align: right;">
          <a-button type="primary" @click="handleOk">确定</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { Upload } from 'ant-design-vue';
export default {
  data() {
    return {
      headers: {
        authorization: 'authorization-text',
      },
      fileList: [],
      openModal: false,
      result: {}
    }
  },

  methods: {
    async download(api) {
      let action = {
        url: api,
        newPage: true
      };
      let rows = [];
      const urlStr = action.url.replace(/{(\w+)}/g, (substr, group) => rows[0][group]);
      const url = new URL(/^https?:\/\//.test(urlStr) ? urlStr : window.location.origin + urlStr);
      if (action.newPage) {
        window.open(url.toString());
        this.$message.success('下载成功');
      } else {
        window.location.href = url.toString();
      }
    },

    // 格式验证
    beforeUpload(file) {
      if (file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        this.$message.error('上传文件格式错误');
        return false || Upload.LIST_IGNORE;
      }
    },

    handleChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        this.result = info.file.response;
        this.openModal = true;
        this.$message.success('上传模板成功');
      } else if (info.file.status === 'error') {
        this.$message.error('上传模板失败');
      }
    },

    handleOk() {
      this.openModal = false;
      this.$emit('close')
    }
  }
}
</script>